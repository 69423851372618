import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faRedo } from '@fortawesome/free-solid-svg-icons'
import Image from "./image"
import Tag from "./tag"
const nothumb = 'thumbnail/nothumb.jpg'

const PostLink = ({ post }) => (
  <Post>
    <StyledLink to={"entry/" + post.frontmatter.slug}>
      <FontAwesomeIcon icon={faCalendarAlt}/><Date>{post.frontmatter.postedAt}</Date>{ (post.frontmatter.updatedAt) ? <><FontAwesomeIcon icon={faRedo}/><Date>{post.frontmatter.updatedAt}</Date></> : "" }
      { (post.frontmatter.thumbnail) ? <Image filename={post.frontmatter.thumbnail}/> : <Image filename={nothumb} /> }
      <Title>{post.frontmatter.title}</Title>
      { (post.frontmatter.description) ? <Description>{post.frontmatter.description}</Description> : <Description>{post.excerpt}</Description> }
    </StyledLink>
    <Tag tags={post.frontmatter.tags}></Tag>
  </Post>
)

const Post = styled.div`
  margin-bottom: 50px;
  background-color: #fff;
  padding: .5rem .8rem;;
  border: 1px solid #f1f1f1;
  border-radius: 5px;

  @media only screen and (min-width: 600px) {
    flex: 0 0 calc((100% - 15px) / 2);
    margin-right: 15px;

    &:nth-child(2n) {
        margin-right: 0;
    }
  }

  @media only screen and (min-width: 960px) {
    flex: 0 0 calc((100% - 30px) / 3);

    &:nth-child(2n) {
        margin-right: 15px;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }
  }
`

const Title = styled.h2`
  font-size: 1rem;
  margin: 1rem 0 0;
`

const Description = styled.p`
  font-size: .7rem;
  margin: 1rem 0;
`

const Date = styled.span`
  font-size: .8rem;
  margin-right: 1rem;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: #4078c0;

    picture {
      opacity: .9;
    }
  }
`

export default PostLink
