import React from "react"
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import kebabCase from "lodash/kebabCase"
import Layout from "../components/layout"
import Header from "../components/header"
import PostLink from "../components/post-link"
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges, group },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.postedAt) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <>
    <Header />
    <SEO title="Home" />
    <Layout>
      <PostList>
        {Posts}
      </PostList>
      <TagTitle><FontAwesomeIcon icon={faTags} />タグ一覧</TagTitle>
      <TagList>
        {group.map(tag => (
          <li key={tag.fieldValue}>
            <StyledLink to={`/tags/${kebabCase(tag.fieldValue)}`}>
              {tag.fieldValue} ({tag.totalCount})
            </StyledLink>
          </li>
        ))}
      </TagList>
    </Layout>
    </>
    )
}

const PostList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TagTitle = styled.h2`
  font-size: 1rem;
`

const TagList = styled.ul`
  font-size: .7rem;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  li {
    margin-right: .5em;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px 7px;
    background-color: #fff;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: #4078c0;
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___postedAt] }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          id
          excerpt(pruneLength: 140)
          frontmatter {
            postedAt(formatString: "YYYY/MM/DD")
            updatedAt(formatString: "YYYY/MM/DD")
            title
            thumbnail
            tags
            slug
            description
          }
        }
      }
    }
  }
`

export default IndexPage